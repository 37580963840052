import { UniDealerApiC } from '@unikey/unikey-commons/release/comm'
import { UniIdentityApiID } from '@unikey/unikey-commons/release/identity'

export const partnerKey: string = 'idv';
export const environment: string = 'staging';

const commNonceEndpoint = { nonceEndpoint: 'https://idv-stg-dealer-api.unikey.com/v5/nonces' };
export const api: UniDealerApiC = new UniDealerApiC('https://idv-stg-dealer-api.unikey.com', 'v5', 'idv'.toUpperCase(), { enableLogging: environment === 'development', preventRequestSubscriptions: '' as string === 'true', ...commNonceEndpoint })
export const identityApi: UniIdentityApiID = new UniIdentityApiID('https://identity-stg.unikey.com', 'api/v1', '7485fd5d-73b9-44e6-97dd-bf3d1d936891', 'AtX6J7uhdvYzGsCwXU02xMz/Of8a9UYPLq3mJYd+bYGaxfuV+4Sf5xVcyI75eS/he47BYcW7beAg3ZXNEWaztA==', { enableLogging: environment === 'development' && false, preventRequestSubscriptions: '' as string === 'true', ...commNonceEndpoint });

export const captchaSiteKey: string = '6Lcvk8QUAAAAAJBIxU1ev4ff2GoeNbpW-jJw0SWR';
export const captchaSecretKey: string = '6Lcvk8QUAAAAACFaEFWQN9CuwDySNbpV4HY9xyS3';

export const termsOfServiceDocument: string = '/legal/termsofuse';
export const privacyPolicyDocument: string = '/legal/privacypolicy';
export const partnerSupportLink: string = 'https://support.identiv.com/';
export const deeplinkDomain = 'https://idv-stg-dealer-web.unikey.com'

export const appInsightsInstrumentationKey: string = 'c0ac2391-a5f5-4d59-9738-906c02109136';
export const mockAuth: string = '';

export const iosAppStoreLink: string = 'https://install.appcenter.ms/orgs/UniKey-Technologies/apps/Identiv-Staging';
export const googlePlayStoreLink: string = 'https://appcenter.ms/orgs/UniKey-Technologies/apps/Identiv-Staging-1';

export const oidcScope: string = 'openid email profile comm.dealer.api identity.api offline_access';
export const oidcClientId: string = '7485fd5d-73b9-44e6-97dd-bf3d1d936891';
export const oidcAuthority: string = 'https://identity-stg.unikey.com/.well-known/openid-configuration';
export const oidcRedirectUri: string = 'https://idv-stg-web.unikey.com/#/token';
export const oidcRegisterUri: string = '';
export const oidcClientSecret: string = 'AtX6J7uhdvYzGsCwXU02xMz/Of8a9UYPLq3mJYd+bYGaxfuV+4Sf5xVcyI75eS/he47BYcW7beAg3ZXNEWaztA==';
export const oidcPartnerBrandId: string = '2d751f71-1db9-4958-a6e0-df58d096dfec';
export const oidcPartnerBrandCode: string = 'IDV';
export const oidcSilentRedirectUri: string = 'https://idv-stg-web.unikey.com/#/token';
export const oidcForgotPasswordRedirect: string = '';

export const buildId: string = '33792';
export const releaseId: string = 'Release-279';
export const cdpVersion: string = '5.3.1';
export const commonsVersion: string = '7.5.1';
export const desiredLocales: string = 'en,es,ja,fr,pt';